import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../css/common.css";
import "../css/pages/special-freight.css";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Seo from "../components/SeoComponent";

const BlogPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo title={t("blog.metadata.name")} description={t("blog.metadata.description")} />
      <Header />

      <Footer />
    </>
  );
};

export default BlogPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
